<template>
  <v-card :loading="loadingTable">
    <v-card-title class="ml-4 pt-2">
      <span class="primary--text">{{
        title + " " + itemEnvioSiro.nroTransaccion
      }}</span>
    </v-card-title>
    <v-card-text class="py-0">
      <v-col cols="12">
        <v-data-table
          :headers="headersEnvioSiro"
          :items="itemsEnvioSiro"
          height="100%"
          class="elevation-1"
          hide-default-footer
        >
        </v-data-table>
      </v-col>
      <v-col cols="12">
        <v-data-table
          :headers="headersErrors"
          :items="itemsErrors"
          height="100%"
          class="elevation-1"
          item-key="mProvId"
        >
          <template v-slot:top>
            <v-toolbar flat dense>
              <v-toolbar-title class="primary--text"
                >Errores del envio</v-toolbar-title
              >
            </v-toolbar>
          </template>
        </v-data-table>
      </v-col>
    </v-card-text>
    <v-card-actions class="pt-0 mr-8 pb-n2">
      <v-spacer></v-spacer>
      <v-btn outlined @click="closeModal"> Cancelar </v-btn>
    </v-card-actions>
  </v-card>
</template>


<script>
import { mapActions } from "vuex";

export default {
  name: "VerEnvioLoteSiroe",
  props: {
    itemEnvioSiro: {
      type: Object,
      required: true,
      default: null,
    },
  },
  data: () => ({
    title: "Envio",
    dataEnvio: null,
    itemsErrors: [],
    headersErrors: [
      {
        text: "Id",
        sortable: false,
        value: "id",
      },
      {
        text: "Base id",
        sortable: false,
        value: "base_id",
      },
      {
        text: "Descripción error",
        sortable: false,
        align: "left",
        value: "descripcion_error",
      },
      {
        text: "Registro",
        sortable: false,
        align: "left",
        value: "registro",
      },
    ],
    headersEnvioSiro: [
      {
        text: "Registros correctos",
        sortable: false,
        value: "cantidad_registros_correctos",
      },
      {
        text: "Registros erroneos",
        sortable: false,
        value: "cantidad_registros_erroneos",
      },
      {
        text: "Registros procesados",
        sortable: false,
        align: "right",
        value: "cantidad_registros_procesados",
      },
      {
        text: "Descripcion error",
        sortable: false,
        align: "right",
        value: "error_descripcion",
      },
      {
        text: "Estado",
        sortable: false,
        align: "right",
        value: "estado",
      },
      {
        text: "Fecha envio",
        sortable: false,
        align: "right",
        value: "fecha_envio",
      },
      {
        text: "Fecha procesos",
        sortable: false,
        align: "right",
        value: "fecha_proceso",
      },
      {
        text: "Fecha registro",
        sortable: false,
        align: "right",
        value: "fecha_registro",
      },
      {
        text: "Número transacción",
        sortable: false,
        align: "right",
        value: "nro_transaccion",
      },
      {
        text: "Total primer vencimiento",
        sortable: false,
        align: "right",
        value: "total_primer_vencimiento",
      },
      {
        text: "Total segundo vencimiento",
        sortable: false,
        align: "right",
        value: "total_segundo_vencimiento",
      },
      {
        text: "Total tercer vencimiento",
        sortable: false,
        align: "right",
        value: "total_tercer_vencimiento",
      },
      {
        text: "Usuario",
        sortable: false,
        align: "right",
        value: "usuario_id",
      },
      {
        text: "Via ingreso",
        sortable: false,
        align: "left",
        value: "via_ingreso",
      },
    ],
    itemsEnvioSiro: [],
  }),
  created() {
    this.setDataEnvio();
  },
  methods: {
    ...mapActions({
      consultaSiroByNroTran: "devengamientos/consultaSiroByNroTran",
    }),
    async setDataEnvio() {
      this.loadingTable = true;
      const data = {
        envioLoteId: this.itemEnvioSiro.enviosLotesId,
        enviosConfiguracionId: this.itemEnvioSiro.enviosConfiguarionId,
        nroTransaccion: this.itemEnvioSiro.nroTransaccion,
      };
      try {
        const response = await this.consultaSiroByNroTran(data);
        if (response) {
          this.itemsEnvioSiro.push(response);
          this.itemsErrors = response.errores;
        }
        this.loadingTable = false;
      } catch {}
    },
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>